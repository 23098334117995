import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import inlineLogo from "./../assets/TIME TRACKER 740.png";
import verticalLogo from "./../assets/TimeTracker.svg";
import ResponsiveBanner from "../Component/ResponsiveBanner";

// Sample data for the featured product, other products, and services
const featuredProduct = {
  name: "Featured Product",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.",
  image: "./../assets/TIME TRACKER 740.png",
};

const otherProducts = [
  {
    id: "timetracker-extension-for-zohocrm",
    name: "Time Tracker for Zoho CRM",
    description: "Tracks time in Zoho CRM for an user with many features",
    image: (
      <img
        src={verticalLogo}
        alt="Time Tracker"
        style={{
          width: "100%",
          height: 200,
          width: 200,
          objectFit: "cover",
        }}
      />
    ),
  },
];

const services = [
  {
    id: 1,
    name: "Zoho CRM Automation",
    description: (
      <>
        Automate Zoho CRM from{" "}
        <ul>
          <li>Lead creation to Blueprint</li>
          <li>Module modifiaction to automation</li>
          <li>Workflow to 3rd party integration</li>
          <li>Attachment sync to Drive Management</li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    name: "Zoho Books Automation",
    description: (
      <>
        Automate Zoho Books for{" "}
        <ul>
          <li>Customer, Vendor and Item</li>
          <li>Estimates, Sales Order, Invoice</li>
          <li>Purchase Order, Bill</li>
          <li>Attachment sync</li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    name: "Zoho Projects Automation",
    description: (
      <>
        Automate Zoho Projects for{" "}
        <ul>
          <li>User management</li>
          <li>Projects, Milestone, Task List and Task automation</li>
          <li>Client billing management</li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    name: "Zoho Desk Automation",
    description: (
      <>
        Automate Zoho Desk for{" "}
        <ul>
          <li>User/Team management</li>
          <li>Client Management for ticketing and support</li>
          <li>Widget Creatio </li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    name: "Zoho FSM",
    description: (
      <>
        Automate Zoho FSM for{" "}
        <ul>
          <li>Work Order management</li>
          <li>Client billing</li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    name: "Zoho Subscription",
    description: (
      <>
        Automate Zoho Subscription for{" "}
        <ul>
          <li>Subscription management</li>
          <li>Client Management for subscription</li>
          <li>Other Zoho product integration</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    name: "3rd Party API Integration",
    description: (
      <>
        3rd Party API Integration for{" "}
        <ul>
          <li>
            Any 3rd party accounting software with Zoho Books (such as bill.com,
            quickbooks)
          </li>
          <li>Any 3rd party database with Zoho CRM</li>
          <li>And many more</li>
        </ul>
      </>
    ),
  },
];

const Homepage = () => {

  const gotoPreviousTimer = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <Container>
      {/* Featured Product Section */}
      <Box my={5}>
        <Typography variant="h4" gutterBottom>
          Core Products
        </Typography>
        <Card
          sx={{
            p: 2,
            border: "2px solid rgba(25, 118, 210, 0.4)",
            borderRadius: "8px",
            "&:hover": {
              bgcolor: "rgba(25, 118, 210, 0.04)",
              outline: "1px solid rgba(25, 118, 210, 1)",
            },
          }}
        >
          {/* <CardMedia
            component="img"
            height="300"
            image={featuredProduct.image}
            alt={featuredProduct.name}
          /> */}

          <CardContent>
            {/* <img
              src={isXs? verticalLogo: inlineLogo}
              alt="Automatic time tracking precisely"
              style={{
                width: "100%",
                height: 340,
                width: isXs? 340: 740,
                objectFit: "cover",
              }}
            /> */}
            <ResponsiveBanner src={inlineLogo} alt="Logo" />
            <Typography variant="h5" gutterBottom>
              Time Tracker for Zoho CRM
            </Typography>
            <Typography variant="body1" gutterBottom>
              This extension tracks time of user against a client based on user
              hourly rate and minimum billable time in Zoho CRM. After
              completion of successfull time log it calculate the amount. Name,
              Description is updated on updatation of time log. it detetects
              whether any previous time log has been running or not. if yes it
              doesn't allow more than 1. user needs to stop previous one.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              sx={{ mr: 2 }}
              
              target="_blank"
              rel="noopener noreferrer"
              // href="https://crm.zoho.com/market/installPrivatePlugin.do?portalName=mdrayhan&nameSpace=timetracker0&versionIndex=4543466000000122076"
              // onClick={gotoPreviousTimer}
              to="https://crm.zoho.com/market/installPrivatePlugin.do?portalName=mdrayhan&nameSpace=timetracker0&versionIndex=4543466000000122076"
            >
              Install Extension
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/product/timetracker-extension-for-zohocrm"
            >
              Go To Docs
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* Services Section */}
      <Box my={5}>
        <Typography variant="h4" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid item key={service.id} xs={12} md={4}>
              <Card
                sx={{
                  height:300,
                  p: 2,
                  border: "2px solid rgba(25, 118, 210, 0.4)",
                  borderRadius: "8px",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(25, 118, 210, 0.04)",
                    outline: "1px solid rgba(25, 118, 210, 1)",
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h5">{service.name}</Typography>
                  <Typography variant="body1">{service.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      
      {/* Other Products Section */}
      <Box my={5}>
        <Typography variant="h4" gutterBottom>
          Other Products
        </Typography>
        <Grid container spacing={4}>
          {otherProducts.map((product) => (
            <Grid item key={product.id} xs={12} md={4}>
              <Card
                sx={{
                  p: 2,
                  border: "2px solid rgba(25, 118, 210, 0.4)",
                  borderRadius: "8px",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(25, 118, 210, 0.04)",
                    outline: "1px solid rgba(25, 118, 210, 1)",
                  },
                }}
              >
                {/* <CardMedia
                  component="img"
                  height="200"
                  image={product.image}
                  alt={product.name}
                /> */}
                {product?.image}
                <CardContent>
                  <Typography variant="h5">{product.name}</Typography>
                  <Typography variant="body1">{product.description}</Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/product/${product.id}`}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

    </Container>
  );
};

export default Homepage;
