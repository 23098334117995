import React from "react";
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import verticalLogo from "./../../../assets/mojaran (2).svg";
import createTimer from "./../../../assets/createTimer.png";
import updateTimer from "./../../../assets/updateTimer.png";
import setting from "./../../../assets/setting.png";
import relatedList from "./../../../assets/relatedList.png";
import { inlineLogo } from "../../../Data/projectVariable";

// Table of Contents component
const TableOfContents = ({ sections }) => {
  const handleClick = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: "20px",
        bgcolor: "background.paper",
        p: 2,
        my:2,
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        User Guide for Time Tracker
      </Typography>
      <List component="nav">
        {sections.map((section) => (
          <ListItem
            button
            key={section.id}
            onClick={() => handleClick(section.id)}
          >
            <ListItemText primary={section.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

function TimeTrackerUserGuide(props) {
  const sections = [
    {
      id: "introduction",
      title: "Introduction",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Introduction
            </Typography>
            <Typography variant="body1">
              An user can use the time tracker extension in a record using
              button widget in details page. If an user has permission to access
              the button then he can use it. It supports all module. For custom
              module please see our documentation or video
            </Typography>
            <br />
            <Typography variant="h6">
              To check permission of time tracking
            </Typography>
            <ul>
              <li>Log in to your Zoho CRM account</li>
              <li>
                Go to any record where time tracker button is enable for you
              </li>
              <li>
                If you can see 'Start Time Tracker' button you are good to go
              </li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "settingupTimeTrackerButton",
      title: "Setting Up Time Tracker Button",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Setting Up Time Tracker Button
            </Typography>
            <img
              src={createTimer}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">To start a timer for a task:</Typography>
            <ul>
              <li>
                Go to any record where time tracker button is enable for you
              </li>
              <li>
                Click 'Start Time Tracker' button and timer will strat running
              </li>
              <li>Fill up Name(required) and description(optional)</li>

              <ul>
                <li>For auto time tracking press 'Initiate Timer' button</li>
                <li>
                  For manual check the 'I want to input manually' Checkbox and
                  press 'Create Manual Timer' button
                </li>
              </ul>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "updatingaTimer",
      title: "Updating a Timer",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Update a Timer
            </Typography>
            <img
              src={updateTimer}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">To start a timer for a task:</Typography>
            <ul>
              <li>
                Go to any record where time tracker button is enable for you
              </li>
              <li>Click 'Start Time Tracker' button</li>
              <li>
                If any of you time log is incomplete it will displayed in widget
              </li>
              <li>
                Click on that timer you will be redirected to actual record
                where time tracker is running
              </li>
              <li>Make necessary change in Name and description if needed</li>
              <li>Press 'Keep Running' button and timesheet will be updated</li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "stoppingaTimer",
      title: "Stopping a Timer",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Stop Timer
            </Typography>
            <img
              src={updateTimer}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">To start a timer for a task:</Typography>
            <ul>
              <li>
                Go to any record where time tracker button is enable for you
              </li>
              <li>Click 'Start Time Tracker' button</li>
              <li>
                If any of you time log is incomplete it will displayed in widget
              </li>
              <li>
                Click on that timer you will be redirected to actual record
                where time tracker is running
              </li>
              <li>Make necessary change in Name and description if needed</li>
              <li>
                Press 'Complete Timesheet' button and timesheet will be
                completed
              </li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "viewingTimeEntries",
      title: "Viewing Time Entries",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              View Time Entries
            </Typography>
            <img
              src={relatedList}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">To start a timer for a task:</Typography>
            <ul>
              <li>
                Go to any record where time tracker button is enable for you
              </li>
              <li>
                Click on 'Time Trackers' related list under the left side
                related list menu
              </li>
              <li>You will see all time log associated with this record</li>
              <li>
                Click add column button of related list to make some changes if
                needed
              </li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "troubleshooting",
      title: "Troubleshooting",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Troubleshoot
            </Typography>
            <img
              src="https://plus.unsplash.com/premium_photo-1682125784386-d6571f1ac86a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRpbWV8ZW58MHx8MHx8fDA%3D"
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "200px", objectFit: "cover" }}
            />
            <Typography variant="h6">To troubleshoot a time log:</Typography>
            <ul>
              <li>Step: 1. Click 'Start Time Tracker' button</li>
              <ul>
                <li>If there is multiple timer on they will enlisted</li>
                <li>Go to the record clicking on previous timer</li>
                <li>Go to related list(Time Trackers)</li>
                <li>Go to imcoplete time log</li>
                <li>Check the record as finished</li>
              </ul>
              
              <li>Repeat step one for all previous timer</li>
              <li>Once you have no previous timer, tracktime and see how billing is changing</li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TableOfContents sections={sections} />
        </Grid>
        <Grid item xs={12} md={9}>
          {sections.map((section) => (
            <Box key={section.id} id={section.id} sx={{ py: 4 }}>
              {section.content}
              {/* <Typography variant="body1">{section.content}</Typography> */}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TimeTrackerUserGuide;
