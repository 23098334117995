import React from "react";
import { Container, Box, Typography, Grid, Link } from "@mui/material";
import { Email, Phone, LocationOn, WhatsApp } from "@mui/icons-material";

const Contact = () => {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationOn sx={{ mr: 2 }} />
                <Typography variant="body1">
                  East Farider Para, Chandgaon, Chittagong, Bangladesh
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Phone sx={{ mr: 2 }} />
                <Typography variant="body1">+8801766324950</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Email sx={{ mr: 2 }} />
                <Typography variant="body1">
                  <Link href="mailto:m666362@gmail.com">m666362@gmail.com</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WhatsApp sx={{ mr: 2 }} />
                <Typography variant="body1">
                  <Link
                    href="https://wa.me/008801766324950"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WhatsApp Chat
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Contact;
