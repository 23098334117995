import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

const videos = [
  {
    title: "Introduction to Time Tracker",
    url: "https://www.youtube.com/embed/J-C53cHHYeA",
  },
  {
    title: "Set Time Tracker for custom module",
    url: "https://www.youtube.com/embed/pNznry87BlM",
  },
  {
    title: "Start timer immediate on page load",
    url: "https://www.youtube.com/embed/eHTG1c_1Vq0",
  },
  // Add more video objects as needed
];

const TimeTrackerVideo = () => {
  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Typography component="h1" variant="h4" gutterBottom align="center">
        Product Videos
      </Typography>
      <Grid container spacing={4}>
        {videos.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: 300 }}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
                }}
              >
                <iframe
                  src={video.url}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </Box>
              <CardContent>
                <Typography variant="h6" component="div">
                  {video.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TimeTrackerVideo;
