import React, { useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";
import { Container, Box, Typography, Grid, Link } from "@mui/material";
import { Email, Phone, LocationOn, WhatsApp } from "@mui/icons-material";

const TimeTrackerHelp = () => {
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
          mb: 8,
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Please schedule a meeting for help
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            paddingTop: "75%", // Aspect ratio 4:3 (height / width = 3/4)
          }}
        >
          <iframe
            src="https://cal.com/md-rayhan-9464/20min" // Replace with your Cal.com URL
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="Schedule Meeting"
            allowFullScreen
          />
        </Box>
      </Box>
    </Container>
  );
};

export default TimeTrackerHelp;
