import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ResponsiveBanner = ({ src, alt }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  let logoSize;

  if (isXs) {
    logoSize = '200px';
  } else if (isSm) {
    logoSize = '400px';
  } else if (isMd) {
    logoSize = '740px';
  } else if (isLg) {
    logoSize = '900px';
  } else if (isXl) {
    logoSize = '1040px';
  }

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width: logoSize,
        height: 'auto',
      }}
    />
  );
};

export default ResponsiveBanner;