import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Link as MuiLink,
  TextField,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import verticalLogo from "./../assets/mojaran (2).svg";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#f8f8f8", padding: "40px 0" }}>
      <Container>
        <Grid container spacing={4}>
          {/* Section 1: Logo, Name, Slogan and Social Media Links */}
          <Grid item xs={12} md={3}>
            <Box>
              <img
                src={verticalLogo}
                alt="Logo"
                style={{ width: 200, height: 200 }}
              />
              {/* <Box display="flex" justifyContent="start">
                <MuiLink
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener"
                >
                  <FacebookIcon fontSize="large" />
                </MuiLink>
                <MuiLink
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener"
                  sx={{ ml: 2 }}
                >
                  <TwitterIcon fontSize="large" />
                </MuiLink>
                <MuiLink
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener"
                  sx={{ ml: 2 }}
                >
                  <InstagramIcon fontSize="large" />
                </MuiLink>
              </Box> */}
            </Box>
          </Grid>

          {/* Section 2: Quick Links */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              <MuiLink component={Link} to="/product/timetracker-extension-for-zohocrm/privacy-policy" underline="hover">
                Privacy Policy
              </MuiLink>
            </Box>
            <Box>
              <MuiLink component={Link} to="/product/timetracker-extension-for-zohocrm/terms-service" underline="hover">
                Terms and Services
              </MuiLink>
            </Box>
          </Grid>

          {/* Section 3: Our Product and Service */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Our Products and Services
            </Typography>
            <Box>
              <MuiLink
                component={Link}
                to="/product/timetracker-extension-for-zohocrm"
                underline="hover"
              >
                Time Tracker for Zoho CRM
              </MuiLink>
            </Box>
          </Grid>

          {/* Section 4: Contact Us */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>

            <Box>
              <MuiLink component={Link} to="/contact" underline="hover">
                Details
              </MuiLink>
            </Box>
            <Box>
              <MuiLink
                component={Link}
                to="/product/timetracker-extension-for-zohocrm/help"
                underline="hover"
              >
                Schedule a meeting
              </MuiLink>
            </Box>
            {/* <Box component="form" noValidate autoComplete="off">
              <TextField
                fullWidth
                label="Name"
                margin="normal"
                variant="outlined"
                size="small"
              />
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                size="small"
              />
              <TextField
                fullWidth
                label="WhatsApp"
                margin="normal"
                variant="outlined"
                size="small"
              />
              <Button variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Box> */}
          </Grid>
        </Grid>
      </Container>

      <Box
        component="footer"
        py={1}
        bgcolor="text.secondary"
        color="white"
        textAlign="center"
      >
        <Container>
          <Typography variant="body1">
            &copy; 2024 Mojaran. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
