import React from "react";
import { Container, Typography } from "@mui/material";

const TimerTrackerTermsAndServiceDoc = () => {
  return (
    <Container>
      <br />
      <Typography variant="h4" gutterBottom>
        General Terms of Service for Time Tracker
      </Typography>

      <Typography variant="body2" gutterBottom>
        <strong>Last updated</strong>: 01 June, 2024
      </Typography>

      <Typography variant="p" gutterBottom>
        <br />
        <strong>Introduction</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        This Agreement is made and entered into between Time Tracker (referred
        to as “We,” “Us,” or “Our”) and the individual or entity (referred to as
        “You” or “Your”) utilizing our products and services. By accessing and
        using our services, you agree to comply with and be bound by the terms
        and conditions outlined in this Agreement. If you do not agree to these
        terms, please refrain from using our services.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>1. Acceptance of Terms</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        By using our products and services, You acknowledge that You have read,
        understood, and agree to abide by these Terms of Service. If You
        disagree with any part of these terms, please do not use our services.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>2. Description of Services</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Time Tracker provides a range of services, including but not limited to
        building extensions for Zoho Applications, Zoho CRM Consulting, Zoho
        Development, and Zoho Customization. These services are designed to
        enhance your experience with Zoho applications and platforms.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>3. User Obligations</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        You are responsible for providing accurate and complete information
        during the registration process and for maintaining the confidentiality
        of your account credentials. You agree not to engage in any activity
        that may violate these Terms of Service, infringe upon intellectual
        property rights, or disrupt the integrity of our services.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>4. Intellectual Property</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        All intellectual property rights related to our services, including but
        not limited to software, content, trademarks, and logos, are owned by
        Time Tracker. You are granted a limited, non-exclusive, and
        non-transferable license to use our services for their intended purpose.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>5. Limitation of Liability</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Time Tracker shall not be liable for any indirect, incidental, special,
        or consequential damages arising from the use or inability to use our
        services. Our liability, if any, shall not exceed the fees paid by You
        for the specific services.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>6. Termination</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        We reserve the right to terminate or suspend Your access to our services
        if You violate these Terms of Service or engage in activities that are
        harmful to our platform or other users. You may also terminate your use
        of our services at any time.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>7. Privacy</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your use of our services is subject to our Privacy Policy, which
        outlines how we collect, use, and protect your personal information. By
        using our services, You consent to the practices described in our
        Privacy Policy.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>8. Modifications to Terms</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Time Tracker reserves the right to modify these Terms of Service at any
        time. We will provide notice of significant changes through appropriate
        channels. Your continued use of our services after such changes will
        signify your acceptance of the modified terms.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>9. Contact Information</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        If You have any questions, concerns, or inquiries regarding these Terms
        of Service, please contact us at m666363@gmail.com.
      </Typography>

      <Typography variant="p" gutterBottom>
        <br /> <strong>End of Terms of Service</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        By using our services, You acknowledge that You have read and agreed to
        the terms outlined in this Agreement. If You have any questions about
        these terms, please contact us before proceeding.
      </Typography>
      <br />
      <br />
      {/* Add more sections as needed */}
    </Container>
  );
};

export default TimerTrackerTermsAndServiceDoc;
