import React, { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import Header from "./Component/Header";
import Home from "./Pages/Home";
import Footer from "./Component/Footer";
import TimeTracker from "./Product/TimeTracker";
import Contact from "./Component/Contact";
import About from "./Pages/About";
import Product from "./Pages/Product";
import TimeTrackerPrivacyPolicy from "./Component/Time Tracker/TimeTrackerPrivacyPolicy";
import TimerTrackerTermsAndServiceDoc from "./Component/Time Tracker/TimerTrackerTermsAndServiceDoc";
import TimeTrackerUserGuide from "./Component/Time Tracker/User Guide LIne/TimeTrackerUserGuide";
import TimeTrackerAdminGuide from "./Component/Time Tracker/Admin Guide Line/TimeTrackerAdminGuide";
import TimeTrackerHelp from "./Component/Time Tracker/TimeTrackerHelp";
import TimeTrackerCaseStudy from "./Component/Time Tracker/TimeTrackerCaseStudy";
import TimeTrackerVideo from "./Component/Time Tracker/TimeTrackerVideo";

const App = () => {

  // useEffect(() => {
  //   (async function () {
  //     const cal = await getCalApi({});
  //     cal("floatingButton", { calLink: "md-rayhan-9464/20min" });
  //     cal("ui", {
  //       styles: { branding: { brandColor: "#000000" } },
  //       hideEventTypeDetails: false,
  //       layout: "month_view",
  //     });
  //   })();
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/timetracker-extension-for-zohocrm" element={<TimeTracker />} />
          <Route
            path="/product/timetracker-extension-for-zohocrm/privacy-policy"
            element={<TimeTrackerPrivacyPolicy />}
          />
          <Route
            path="/product/timetracker-extension-for-zohocrm/terms-service"
            element={<TimerTrackerTermsAndServiceDoc />}
          />
          <Route
            path="/product/timetracker-extension-for-zohocrm/user-guide"
            element={<TimeTrackerUserGuide />}
          />
          <Route
            path="/product/timetracker-extension-for-zohocrm/admin-guide"
            element={<TimeTrackerAdminGuide />}
          />
          <Route
            path="/product/timetracker-extension-for-zohocrm/help"
            element={<TimeTrackerHelp />}
          />
          
          <Route
            path="/product/timetracker-extension-for-zohocrm/case-study"
            element={<TimeTrackerCaseStudy />}
          />
          <Route
            path="/product/timetracker-extension-for-zohocrm/video"
            element={<TimeTrackerVideo />}
          />
          <Route path="/product" element={<Product />} />
          <Route path="/about" element={<Contact />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
