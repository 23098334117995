import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  CardActionArea,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { timeTrackerDoc } from "../Data/projectVariable";
import inlineLogo from "./../assets/TIME TRACKER 740.png";

// Sample data for features and FAQs (replace with your actual data)
const features = [
  {
    id: 1,
    title: "Automatic time tracking precisely",
    content:
      "As soon as the 'Start Time Tracker' button is pressed, it start time and enable to put name and description.",
    image:
      "https://plus.unsplash.com/premium_photo-1682125784386-d6571f1ac86a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    title: "Record based time entry list",
    content:
      "An user can track time based on record so that we can make the billing section more clear",
    image:
      "https://images.unsplash.com/photo-1616198814651-e71f960c3180?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    title: "Horly billing system for every user",
    content:
      "Admin/Installer can set hourly rate for every user of his organization and it will reflect in billing section",
    image:
      "https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },

  {
    id: 1,
    title: "Calculate the amount billed",
    content:
      "Based on logged time and hourly rate Total amount is auto populated by the extension",
    image:
      "https://plus.unsplash.com/premium_photo-1682125784386-d6571f1ac86a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    title: "Minimum billable duration for every user",
    content:
      "Admin/Installer can set minimum billable duration in minute for every user of his organization and it will reflect in billing section",
    image:
      "https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    title: "Detect if any previous timer is incomplete or Note",
    content:
      "If a user move on to another record (without completing the current timer) and try to start time he will be notified about his last incomplete timer.",
    image:
      "https://images.unsplash.com/photo-1616198814651-e71f960c3180?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },

  {
    id: 1,
    title: "Redirect to previously incomplete timer Record",
    content:
      "If a user move on to another record (without completing the current timer) and try to start time he will be notified about his last incomplete timer and from that he will be redirected to incomplete timer record on just a button press.",
    image:
      "https://plus.unsplash.com/premium_photo-1682125784386-d6571f1ac86a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    title: "Note feature",
    content:
      "On completion of a timer a note will be created based on description of a timer so that client can track for which activity this timer has been logged.",
    image:
      "https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },

  {
    id: 1,
    title: "All module support",
    content: "It supports all module. Please watch this.",
    image:
      "https://plus.unsplash.com/premium_photo-1682125784386-d6571f1ac86a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    title: "Start timer automatically",
    content:
      "This extension can be used to Start Timer automatically as soon as an user click on a record. Please watch this.",
    image:
      "https://images.unsplash.com/photo-1616198814651-e71f960c3180?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    title:
      "Name/Description update whenever its needed to track what is being happening with this time log",
    content:
      "Working on a time consuming task, an user can keep updating description of a timer. Later it can be found in Notes",
    image:
      "https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },

  {
    id: 2,
    title: "Manual time entry",
    content:
      "Manual timer option is also avalable and it is differentiable from automatic logged time",
    image:
      "https://images.unsplash.com/photo-1616198814651-e71f960c3180?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHRpbWV8ZW58MHx8MHx8fDA%3D",
  },
];

const faqs = [
  {
    id: 1,
    question: "Can I use it for all modules?",
    answer: "Yes, You can use it for all module including custom module",
  },
  {
    id: 2,
    question: "Can I get support?",
    answer:
      "Certainly! Our team is always available to provide you with the support you need. We believe in delivering exceptional customer service and strive to ensure that all of your questions and concerns are addressed in a timely and efficient manner. Whether you need help with a technical issue or just have some questions about our services, our support team is here to assist you every step of the way. Don’t hesitate to reach out to us, we’re here to help!",
  },
  {
    id: 3,
    question: "What are the features?",
    answer:
      "It is explained in details in feature section. Please see feature section",
  },
  {
    id: 4,
    question: "Will your Extension support my Zoho CRM?",
    answer:
      "Yes, As long as you are using Zoho CRM(any paid plan), you can use our extension",
  },
  {
    id: 4,
    question: "Can I ask for more features?",
    answer: "Yes, You can email us at m666362@gmail.com",
  },
];

const TimeTracker = () => {
  return (
    <Container>
      {/* Header with Logo */}
      <Box display="flex" alignItems="center" my={4}>
        <img
          src={inlineLogo}
          alt="Logo"
          style={{ marginRight: "16px", width: 740, height: 340 }}
        />
      </Box>
      <Typography component="h1" variant="h4" gutterBottom align="center">
        Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={6} key={index}>
            {/* <Card sx={{ minHeight: 400 }}> */}
            <Card sx={{ minHeight: 380 }}>
              <img
                src={feature.image}
                alt={feature.title}
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <CardContent>
                <Typography variant="h6">{feature.title}</Typography>
                <br />
                <Typography>{feature.content}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <br />

      <Typography component="h1" variant="h4" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq) => (
        <Accordion key={faq.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Container component="main" maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Typography component="h1" variant="h4" gutterBottom align="center">
          Important Links
        </Typography>
        <Grid container spacing={4}>
          {timeTrackerDoc.map((link, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                component={Link}
                to={link?.to}
              >
                {link?.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* {timeTrackerDoc?.map((button, index) => (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={button?.to}
        >
          {button?.name}
        </Button>
      ))} */}
    </Container>
  );

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Product Details
      </Typography>

      <Typography variant="h4" gutterBottom>
        Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature) => (
          <Grid item xs={12} md={4} key={feature.id}>
            <Card>
              <img
                src={feature.image}
                alt={feature.title}
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <CardContent>
                <Typography variant="h5">{feature.title}</Typography>
                <Typography>{feature.content}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <br />
      <br />
      <Typography variant="h5" gutterBottom>
        <strong>Frequently Asked Questions</strong>
      </Typography>
      {faqs.map((faq) => (
        <Accordion key={faq.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <strong>{faq.question}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <br />
      <br />
    </Container>
  );
};

export default TimeTracker;
