import React from "react";
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Link,
} from "@mui/material";
import verticalLogo from "./../../../assets/mojaran (2).svg";
import timeTackerBanner from "./../../../assets/TIME TRACKER 740.png";
import createTimer from "./../../../assets/createTimer.png";
import updateTimer from "./../../../assets/updateTimer.png";
import setting from "./../../../assets/setting.png";
import relatedList from "./../../../assets/relatedList.png";
import { inlineLogo, timeTrackerFeatures } from "../../../Data/projectVariable";

// Table of Contents component
const TableOfContents = ({ sections }) => {
  const handleClick = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: "20px",
        bgcolor: "background.paper",
        p: 2,
        my: 2,
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Admin Guide for Time Tracker
      </Typography>
      <List component="nav">
        {sections.map((section) => (
          <ListItem
            button
            key={section.id}
            onClick={() => handleClick(section.id)}
          >
            <ListItemText primary={section.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

function TimeTrackerAdminGuide(props) {
  const sections = [
    {
      id: "introduction",
      title: "Introduction",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Introduction
            </Typography>
            <img
              src={timeTackerBanner}
              alt="Time Tracker Banner"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="body1">
              Time tracker is zoho crm based extension used for organization's
              user time logger. An organiztion user time log can easily handled
              by using this extension along with client billing. There are some
              features descrired briefly
            </Typography>
            <br />
            <Typography variant="h6">Features</Typography>
            <ul>
              {timeTrackerFeatures?.map((feature, index) => {
                return <li>{feature?.name}</li>;
              })}
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "howToInstall",
      title: "How To Install",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              How to install Time Tracker extension
            </Typography>
            <img
              src={timeTackerBanner}
              alt="Time Tracker Banner"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">How to install our extension</Typography>
            <ul>
              <li>Log in Zoho CRM</li>
              <li>
                In the same brower open this{" "}
                <Link
                  href={
                    "https://crm.zoho.com/market/installPrivatePlugin.do?portalName=mdrayhan&nameSpace=timetracker0&versionIndex=4543466000000122076"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  link
                </Link>
              </li>
              <li>Check both checkbox and press 'Continue' button</li>
              <li>Choose User/Profile and press 'Confirm' button</li>
              <li>
                For paid subscription select the package and press 'Pay now'
                button
              </li>
              <li>For trial there is an option for 10 days trial as well</li>
              <li>
                It may take few moments to install in our Zoho CRM and you can
                use instantly
              </li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "settingupUserHourlyRate",
      title: "Setting Up User Hourly Rate",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Setting Up User Hourly Rate and Minimum Billable Minute
            </Typography>
            <img
              src={setting}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">
              To set user hourly rate and minimum billable minute
            </Typography>
            <ul>
              <li>Log in Zoho CRM</li>
              <li>{`Goto Setting > All (under Marketplace) > Installed`}</li>
              <li>Click 'Setting' under Time Tracker</li>
              <li>A form will be displayed under 'User Billing' Tab</li>
              <li>Click 'Add User' button</li>
              <li>
                Select user and modify hourly rate and minimum billable minute
              </li>
              <li>Add as many user as you want</li>
              <li>Click 'Submit' button</li>
              <li>On successfull submission a toast message will appear</li>

              <li>Some Important Note</li>
              <ul>
                <li>
                  Unlisted user hourly rate and minimum billable minute will be
                  1(default)
                </li>

                <li>
                  Total amount calculation is based on total time tracked (in
                  secornd) and minimum billable minute
                </li>
              </ul>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "settingupOtherSetting",
      title: "Setting Up Other(Organization) Setting",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Setting Up Note, Manual time tracker setting
            </Typography>
            <img
              src={setting}
              alt="Automatic time tracking precisely"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Typography variant="h6">
              To set Note, Manual time tracker setting
            </Typography>
            <ul>
              <li>Log in Zoho CRM</li>
              <li>{`Goto Setting > All (under Marketplace) > Installed`}</li>
              <li>Click 'Setting' under Time Tracker</li>
              <li>Click on 'Other Setting' Tab</li>
              <li>Check/Uncheck the checkbox if need</li>
              <li>Click 'Submit' button</li>
              <li>On successfull submission a toast message will appear</li>

              <li>Some Important Note</li>
              <ul>
                <li>Setting set here reflect for all user of organization</li>
              </ul>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "enablePermissionForUser",
      title: "Enable Permission for Profile",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Button Permission
            </Typography>
            <Typography variant="h6">Enable Permission for Profile:</Typography>
            <ul>
              <li>Log in Zoho CRM</li>
              <li>{`Goto Setting > Modules and Fields (under Customization )`}</li>
              <li>Select module</li>
              <li>Goto 'Button' tab</li>
              <li>
                Click on Gear icon adjacent to 'Start Time Tracker' button
              </li>
              <li>Select Set Permission</li>
              <li>Select Profile and save it</li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    {
      id: "setUpForCustomModule",
      title: "Set Time Tracker For Custom Module",
      content: (
        <Card sx={{ minHeight: 380 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              Time Tracker Setup for Custom Module Setup
            </Typography>
            <Typography variant="h6">
              Create Buton, Widget and associate widget with button
            </Typography>

            <ul>
              <li>Log in Zoho CRM</li>
              <li>{`Goto Setting > Modules and Fields (under Customization )`}</li>
              <li>Select module</li>
              <li>Goto 'Button' tab</li>
              <li>Click 'Create New Button' button</li>
              <li>Put 'Button Name' = 'Start Time Tacker'</li>
              <li>Select layout as many as you want</li>
              <li>Select 'Choose a page to place the button' = 'Record'</li>
              <li>Select 'Choose a position in the page' = 'Details'</li>
              <li>
                Select 'Define an action to be performed' = 'Open a widget'
              </li>
              <li>Select '+ Create New Widget' button at top right of popup</li>
              <li>Put 'Name' = 'Time Tracker Widget'</li>
              <li>Select 'Hosting' = 'external'</li>
              <li>
                Put 'Base URL' =
                'https://timetracker-extension-for-zohocrm.vercel.app/button'
              </li>
              <li>Click 'Save' button</li>
              <li>After saving click</li>
              <li>
                Now scroll down to previously created widget called 'Time
                Tracker Widget' and press 'Install' button
              </li>
              <li>Finally select profile and save it</li>
              <li>
                our extension widget will be available in desired(custom) module
              </li>
            </ul>
          </CardContent>
        </Card>
      ),
    },
    // {
    //   id: "startTimerAutomatically",
    //   title: "Start Timer Automatically",
    //   content: (
    //     <Card sx={{ minHeight: 380 }}>
    //       <CardContent>
    //         <Typography variant="h4" component="h2" gutterBottom>
    //           Start Timer Automatically
    //         </Typography>
    //         <Typography variant="h6">
    //           Automatic Timer starter as soon as any record accessed
    //         </Typography>

    //         <ul>
    //           <li>Log in Zoho CRM</li>
    //         </ul>
    //       </CardContent>
    //     </Card>
    //   ),
    // },
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TableOfContents sections={sections} />
        </Grid>
        <Grid item xs={12} md={9}>
          {sections.map((section) => (
            <Box key={section.id} id={section.id} sx={{ py: 4 }}>
              {section.content}
              {/* <Typography variant="body1">{section.content}</Typography> */}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TimeTrackerAdminGuide;
