import React from "react";
import { Container, Box, Typography, Paper } from "@mui/material";

const TimeTrackerCaseStudy = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Case Study: Improving Time Tracking Efficiency
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography component="h2" variant="h5" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            This case study examines how our Time Tracker extension helped a
            mid-sized company streamline their time tracking and billing
            process, leading to increased productivity and profitability.
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography component="h2" variant="h5" gutterBottom>
            Problem Statement
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                <strong>Client Billing Complexity:</strong> Managing client
                billing based on individual user hourly rates was cumbersome and
                error-prone.
              </li>
              <li>
                <strong>Invoicing Inefficiency:</strong> Generating invoices
                with detailed time logs for each client at the end of the month
                was time-consuming.
              </li>
              <li>
                <strong>Payment Tracking:</strong> Tracking client payments and
                managing outstanding dues for each client was challenging.
              </li>
              <li>
                <strong>Work Monitoring:</strong>
                Identifying when the due amount for a client exceeded $1000 to
                alert users for future work details.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography component="h2" variant="h5" gutterBottom>
            Solution
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                <strong>Automated Time Tracking:</strong> Our tool tracks user
                time against clients, calculates billing based on individual
                hourly rates, and updates client details
              </li>
              <li>
                <strong>Invoice Automation:</strong> The tool automatically
                generates detailed time log attachments for client invoices,
                streamlining the invoicing process.
              </li>
              <li>
                <strong>Payment Tracking:</strong> Users can track client
                payments, update payment details, and manage outstanding dues
                efficiently within Zoho CRM.
              </li>
              <li>
                <strong>Work Monitoring:</strong> A warning message appears when
                the due amount for a client exceeds $1000, alerting users for
                future work details.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography component="h2" variant="h5" gutterBottom>
            Results
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                <strong>Improved Billing Accuracy:</strong>By automating time
                tracking and client billing, organizations experienced increased
                accuracy in client invoicing.
              </li>
              <li>
                <strong>Efficiency Gains:</strong>The automation of invoicing
                and payment tracking saved time and resources, leading to
                improved efficiency.
              </li>
              <li>
                <strong>Enhanced Work Monitoring:</strong>Users were alerted
                when due amounts exceeded $1000, enabling proactive management
                of future work details.
              </li>
              <li>
                <strong>Client Satisfaction:</strong>With timely and accurate
                invoicing, clients appreciated the transparency and accuracy in
                billing processes.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography component="h2" variant="h5" gutterBottom>
            Conclusion
          </Typography>
          <Typography variant="body1" paragraph>
            In conclusion, our time tracking and client billing automation tool
            in Zoho CRM has significantly improved efficiency, accuracy, and
            client satisfaction for organizations. By addressing the challenges
            of client billing complexity, invoicing inefficiency, payment
            tracking, and work monitoring, our tool has become an indispensable
            solution for organizations looking to streamline their client
            billing processes.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TimeTrackerCaseStudy;
